module controllers {
    export module logistics {
        interface ICommercialInvoiceCreateScope extends ng.IScope {
        }

        interface ICommercialInvoiceCreateParams extends ng.ui.IStateParamsService {
            consignmentId: number;
        }

        export class commercialInvoiceCreateNewCtrl
        {
            static $inject = [
                "$scope",
                "$rootScope",
                "$timeout",
                "generalService",
                "$state",
                "entityService",
                "counterService",
                "$q",
                "bsLoadingOverlayService",
                "isInbound",
                "supplierService",
                "customerService",
                "$stateParams",
                "commercialInvoiceService",
                "consignmentService",
                'currencyService'];

            //Entity Variables
            ownerEntityId: number;

            IsLoading: boolean;

            //Counter
            invoiceCounterModel: interfaces.applicationcore.IDropdownModel;
            invoiceCounterTypeDefer: ng.IDeferred<any>;
            invoiceCounterList: Array<interfaces.applicationcore.IDropdownModel>;
            
            //Supplier Variables
            supplierModel: interfaces.applicationcore.IDropdownModel;
            supplierList: Array<interfaces.applicationcore.IDropdownModel>;

            //Customer Variables
            customerModel: interfaces.applicationcore.IDropdownModel;
            
            public consignment: interfaces.consignment.IConsignment;

            //Custom Number
            invoiceCustomNumber: string;

            consignmentId: number = 0;

            loadPromises: ng.IPromise<any>[];

            invoiceCounterId: number;
            invoiceDate: moment.Moment =  moment().utc();

            currencyCorrectCheck: boolean = false;

            DefaultCurrency: interfaces.applicationcore.IDropdownModel;
            Currency: interfaces.applicationcore.IDropdownModel;
            CurrencyDefaulted: boolean = false;

            consignmentTypeDefaults: interfaces.consignment.ConsignmentTypeDefaults;

            LockSupplier: boolean = false;
            LockCustomer: boolean = false;
            
            constructor(private $scope: ICommercialInvoiceCreateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $timeout: ng.ITimeoutService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private counterService: interfaces.master.ICounterService,
                private $q: ng.IQService,
                private bsLoadingOverlayService,
                private isInbound: boolean,
                private supplierService: interfaces.master.ISupplierService,
                private customerService: interfaces.master.ICustomerService,
                private $stateParams: ICommercialInvoiceCreateParams,
                public commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                public consignmentService: interfaces.consignment.IConsignmentService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
            )
            {
                if (!$stateParams.consignmentId) {
                    return null;
                } else {
                    this.consignmentId = $stateParams.consignmentId;
                }
                //Get Entities Data
                this.loadPromises = [];

                this.loadPromises.push(this.getCurrentConsignment());
                this.loadPromises.push(this.getCurrentEntity());
                this.loadPromises.push(this.loadConsignmentTypeDefaults());


                bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return $q.all(this.loadPromises);
                });
            }

            getCurrentConsignment(){
                return this.consignmentService.GetConsignment(this.consignmentId).get((result: interfaces.consignment.IConsignment) => {
                    this.consignment = result;
                }
                , (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

           

            getCurrentEntity() {
                return this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;

                    this.loadInvoiceCounter();

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }
            
            loadInvoiceCounter() {
                if (this.ownerEntityId) {

                    this.counterService.getDropdownList().query({
                        ownerEntityId: this.ownerEntityId,
                        counterTypeId: this.isInbound?Enum.EnumCounterTypes.ImportCommercialInvoice: Enum.EnumCounterTypes.CommercialInvoice,
                        isInbound: this.isInbound
                    }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                        this.invoiceCounterList = result;

                        if (this.invoiceCounterTypeDefer) {
                            this.invoiceCounterTypeDefer.resolve();
                        }

                        angular.forEach(result, (o) => {
                            if (o.Selected) {
                                this.invoiceCounterModel.Id = o.Id;
                            }
                        });

                    }, (failureData) => {

                        if (this.invoiceCounterTypeDefer) {
                            this.invoiceCounterTypeDefer.reject();
                        }

                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadCurrencies() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });


                return defered.promise;
            }

            loadConsignmentTypeDefaults() {
                return this.consignmentService.GetConsignmentTypeDefaultsByConsignment(this.consignmentId).get((result: interfaces.consignment.ConsignmentTypeDefaults) => {
                    this.consignmentTypeDefaults = result;
                    this.supplierModel = result.Supplier; //this comes from Consignment Type Default

                    if (result.ShipperDefaultSupplier){
                        this.supplierModel = result.ShipperDefaultSupplier; //This comes from the Consignment Shipper - Supplier Default 
                    }

                    this.customerModel = result.Customer;

                    if (this.supplierModel){
                        this.supplierChanged(this.supplierModel);
                    }

                    if (this.customerModel){
                        this.customerChanged(this.customerModel);
                    }

                    this.LockCustomer = false;
                    this.LockSupplier = false;
                    

                    if (result.LockCustomer && this.customerModel){
                        this.LockCustomer = true;
                    }  
                    
                    if (result.LockSupplier && this.supplierModel){
                        this.LockSupplier = true;
                    }                           
                    
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise; 
            }

            getSuppliers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.supplierService.getSupplierDropdown(this.ownerEntityId, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            getCustomers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.customerService.getDropdownList(this.ownerEntityId, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            CreateInvoice() {

                this.IsLoading = true;
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.commercialInvoice'
                }, () => {
                    return this.commercialInvoiceService.CreateCommercialInvoice(

                        this.consignmentId,
                        this.supplierModel.Id,
                        this.customerModel.Id,this.invoiceDate.format("YYYY/MM/DD"),this.Currency.Id,
                        this.invoiceCustomNumber == undefined ? "" : this.invoiceCustomNumber,
                        this.invoiceCounterModel == undefined ? Number(undefined) : this.invoiceCounterModel.Id

                    ).save((result) => {

                        this.generalService.displayMessageHandler(result);

                        this.IsLoading = false;

                        if (!result.HasErrorMessage) {
                            if (this.$stateParams.manId) {
                                this.$state.go("auth.Manifests.Update.ConsignmentImport.CommercialInvoice", { comId: Number(result.ID), conId: this.consignmentId});
                            }
                            else
                            {
                                if (this.consignment.IsInbound)
                                    this.$state.go("auth.ConsignmentImports.Update.CommercialInvoice", { comId: Number(result.ID), conId: this.consignmentId});
                                else
                                    this.$state.go("auth.ConsignmentExports.Update.CommercialInvoice", { comId: Number(result.ID),conId: this.consignmentId })
                            }
                            
                        }

                    }, (reponseError) => {
                        this.IsLoading = false;
                        this.generalService.displayMessageHandler(reponseError.data);

                    }).$promise;
                });
            }

            customInvoiceCounterCheckChanged() {
                this.invoiceCustomNumber = '';

                if (this.invoiceCounterModel != undefined) {
                    this.invoiceCounterList = [];
                    this.invoiceCounterModel = undefined;
                }
                else {
                    this.loadInvoiceCounter();
                }
            }
            
            supplierChanged(model: interfaces.applicationcore.IDropdownModel){
                if (model && this.isInbound) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.commercialInvoiceService.getSupplierDefault(this.ownerEntityId, model.Id, this.isInbound, true).get((result) => {
                            if (result.Currency) {
                                this.Currency = result.Currency;
                                this.DefaultCurrency = result.Currency;
                                this.CurrencyDefaulted = true;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                }
            }

            customerChanged(model: interfaces.applicationcore.IDropdownModel){
                if (model && !this.isInbound) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.commercialInvoiceService.getCustomerDefaultCurrency( model.Id).get((result:interfaces.applicationcore.IDropdownModel) => {
                            if (result) {
                                this.Currency = result;
                                this.DefaultCurrency = result;
                                this.CurrencyDefaulted = true;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                }
            }



            //Navigation
            Close() {
                if (this.consignment.IsInbound){ 
                    this.$state.go("auth.ConsignmentImports.Update", { conId: this.consignmentId })
                }
                else {
                    this.$state.go("auth.ConsignmentExports.Update", { conId: this.consignmentId })
                }
            }
        }

        angular.module("app").controller("commercialInvoiceCreateNewCtrl", controllers.logistics.commercialInvoiceCreateNewCtrl);
    }
}
